import Vue from "vue"
import VueRouter from "./evalmee_common/router_config"
import { autoRedirectToOnboarding, evalmeeOnboarding } from "./evalmee_common/evalmee_onboarding"
import EvalmeeStudentContainer from "../views/evalmee_student/container.vue"
import studentQuiz from "../views/evalmee_student/student_quiz.vue"
import Home from "../views/evalmee_student/home.vue"
import CameraTest from "../views/evalmee_student/camera_test.vue"
import QuizOnboarding from "../views/evalmee_student/exam/evalmee_quiz_onboarding.vue"
import ExamStudentNav from "../views/evalmee_student/exam/evalmee_exam_student_nav.vue"
import quizCorrection from "../views/quizzes/correction.vue"
import answerPhotoUpload from "../views/evalmee_student/answer_photo_upload.vue"
import evalmeeExamStudent from "../views/evalmee_student/exam/evalmee_exam_student.vue"
import evalmeeExamScore from "../views/evalmee_student/exam/evalmee_exam_score.vue"
import quizCamera from "../components/quiz/evalmee/quiz_camera.vue"
import evalmeeHomeNav from "../views/evalmee_student/evalmee_home_nav.vue"
import browserTooOld from "../views/evalmee_student/brower_too_old.vue"
import evalmeeExamRecovery from "../views/evalmee_student/exam/evalmee_exam_recovery.vue"
import { evalmeeLogin } from "./evalmee_common/evalmee_login"
import { evalmeeUserProfile } from "./evalmee_common/user_profile"
import pageNotFound from "../views/page_not_found.vue"
import ltiQuizStudent from "../views/lti/lti_quiz_student.vue"
import proctoringv2Debug from "../views/evalmee_student/proctoringv2-debug.vue"
import "./evalmee_common/router_config"
import store from "../store"
import EvalmeeSessionSelectionView from "@/views/evalmee_student/exam/EvalmeeSessionSelectionView.vue"
import quizPublicLogin from "@/views/quizzes/quizPublicLogin.vue"

Vue.use(VueRouter)

const commonProps = {
  default:(route) => ({
    quizId: parseInt(route.params.quizId),
    quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),

  }),
  main:(route) => ({
    quizId: parseInt(route.params.quizId),
    quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),

  }),
}

// fetch related quizzesAttemptSummaries, then redirect to the first one matching the quizId
// This redirection is needed to handle old links in convocations emails
const fetchQuizzesAttemptSummaryAndRedirect = (to, from, next) => {
  if(to.params.quizzesAttemptSummaryId) return next()
  store.dispatch("fetchQuizzesAttemptSummaries", {
    filter: { quiz_id: to.params.quizId },
  }).then(() => {

    const quizzesAttemptSummary = store.getters.quizzesAttemptSummaries
      .find(s => s.quiz_id === parseInt(to.params.quizId))

    if(quizzesAttemptSummary) {
      next({ name: to.name, params: { quizId: to.params.quizId, quizzesAttemptSummaryId: quizzesAttemptSummary.id } })
    }
    else {
      next({ name: "examsIndex" })
    }
  })
}

const router = new VueRouter({
  routes: [
    ...evalmeeOnboarding,
    ...evalmeeLogin,
    ...evalmeeUserProfile,
    {
      path: "/",
      component: EvalmeeStudentContainer,
      children: [
        {
          path: "/",
          name: "examsIndex",
          components: {
            default: Home,
            nav: evalmeeHomeNav,
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId?/quiz",
          name: "quiz",
          beforeEnter: fetchQuizzesAttemptSummaryAndRedirect,
          redirect: "/exam/:quizId/:quizzesAttemptSummaryId?/sessions",
        },
        {
          path: "/:quizId/:quizzesAttemptSummaryId?/online_exams*",
          name: "quizTeacher",
          redirect: "/exam/:quizId/:quizzesAttemptSummaryId?/onboarding",
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId?/sessions",
          name: "sessionsSelection",
          beforeEnter: fetchQuizzesAttemptSummaryAndRedirect,
          components:{
            default: EvalmeeSessionSelectionView,
            main: studentQuiz,
            nav: ExamStudentNav,
            camera: quizCamera,
          },
          props: {
            ...commonProps,
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
              showBackButton: true,
            }),
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId?/onboarding",
          name: "onboarding",
          beforeEnter: fetchQuizzesAttemptSummaryAndRedirect,
          components:{
            default: QuizOnboarding,
            main: studentQuiz,
            nav: ExamStudentNav,
            camera: quizCamera,
          },
          props: {
            ...commonProps,
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
              showBackButton: true,
            }),
            camera:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
            }),
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId/live",
          name: "exam",
          components:{
            default: evalmeeExamStudent,
            main: studentQuiz,
            nav: ExamStudentNav,
            camera: quizCamera,
          },
          props: {
            ...commonProps,
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
              showBackButton: false,
              showToggleDrawer: true,
            }),
            camera:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
            })          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId?/score",
          name: "score",
          beforeEnter: fetchQuizzesAttemptSummaryAndRedirect,
          components:{
            default: evalmeeExamScore,
            main: studentQuiz,
            nav: ExamStudentNav,
          },
          props: {
            ...commonProps,
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
              showBackButton: true,
            }),
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId/recovery/:instanceId?",
          name: "examRecovery",
          components:{
            default: evalmeeExamRecovery,
            main: studentQuiz,
            nav: ExamStudentNav,
          },
          props: {
            default:(route) => ({
              quizId: parseInt(route.params.quizId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
              instanceId: parseInt(route.params.instanceId),
            }),
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              showBackButton: true,
              titleAppend: "- Recovery",
            }),
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId/correction",
          name: "quizCorrection",
          components:{
            default: quizCorrection,
            main: studentQuiz,
            nav: ExamStudentNav,
          },
          props: {
            ...commonProps,
            nav:(route) => ({
              quizId: parseInt(route.params.quizId),
              showBackButton: true,
              titleAppend: "- Correction",
            }),
          },
        },
        {
          path: "/exam/:quizId/:quizzesAttemptSummaryId/picture_upload/:questionId",
          name: "answerPictureUpload",
          components:{
            default: answerPhotoUpload,
            main: studentQuiz,
          },
          props: {
            ...commonProps,
            default:(route) => ({
              quizId: parseInt(route.params.quizId),
              questionId: parseInt(route.params.questionId),
              quizzesAttemptSummaryId: parseInt(route.params.quizzesAttemptSummaryId),
            }),
          },
        },
        {
          path: "/picture_upload",
          name: "answerPictureUploadSelect",
          components:{
            default: answerPhotoUpload,
            main: studentQuiz,
          },
          props: {
            ...commonProps,
            default:(route) => ({
              quizId: parseInt(route.params.quizId),
              questionId: parseInt(route.params.questionId),
            }),
          },
        },
        {
          path: "/camera_test",
          name: "CameraTest",
          components:{
            default: CameraTest,
          },
          props: {
            default:() => ({}),
          },
        },
        {
          path: "/proctoringv2-debug",
          name: "proctoringv2Debug",
          components:{
            default: proctoringv2Debug,
          },
          props: {
            default:() => ({}),
          },
        },
      ],
    },
    {
      path: "/lti/:quizId",
      name: "LTIStudent",
      component: ltiQuizStudent,
      props: (route) => ({
        quizId: parseInt(route.params.quizId),
      }),
    },
    {
      path: "/join_session/:publicSessionSlug",
      name: "quizPublicLogin",
      component: quizPublicLogin,
      props: (route) => ({
        publicSessionSlug: route.params.publicSessionSlug,
      }),
    },
    {
      path: "/activities",
      name: "activitiesStudent",
      redirect: "/",
    },
    {
      path: "/update_browser",
      name: "oldBrowser",
      component: browserTooOld,
    },
    {
      path: "*",
      component: pageNotFound,
    },
  ],
})

autoRedirectToOnboarding(router)

export default router
