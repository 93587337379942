<template>
  <v-card>
    <v-card-text>
      <v-alert
        text
        type="warning"
        dense
        :icon="false"
      >
        <span>
          Never use this page without instruction from Evalmee support team. Your grade could be affected.
        </span>
      </v-alert>

      Sync: {{ stats.sync }}
      <br>
      Not Sync: {{ stats.notSync }}
      <br>
      QIID : {{ instanceId }}

      <saving-screen
        v-if="isSaving"
        :quiz="quiz"
        :quizzes-attempt-summary="quizzesAttemptSummary"
      />
    </v-card-text>
    <v-card-actions>
      <eva-button
        label="Force Resync All"
        @click="reSyncAll"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import SavingScreen from "../../../components/quiz/saving-screen.vue"

export default {
  name: "EvalmeeExamRecovery",
  props: {
    instanceId: {
      type: Number,
      default: null,
    },
  },
  components: {
    SavingScreen,
  },
  mixins: [quizStudentMixin],

  data: () => ({

  }),

  computed: {
    ...mapGetters([
      "areAllAnswersSynchronized",
      "answersSyncStats",
    ]),
    isSaving() {
      return !this.areAllAnswersSynchronized(
        this.quiz,
        this.currentUser?.id
      )
    },
    stats() {
      return this.answersSyncStats(
        this.quiz,
        this.currentUser?.id
      )
    },
  },

  methods:{
    ...mapActions([
      "fetchMyInstanceByQuiz",
      "fetchQuiz",
      "reSyncAllAnswers",
      "getAnswersFromLocalStorage",
      "fetchQuizzesAttemptSummary",
    ]),

    reSyncAll() {
      this.getLocalAnswers()
      this.reSyncAllAnswers({ quiz: this.quiz, user: this.currentUser, quizzesAttemptSummary: this.quizzesAttemptSummary })
    },
    getLocalAnswers() {
      if(!this.instanceId) return
      this.getAnswersFromLocalStorage({ quiz_id: this.quiz.id, id: this.instanceId })
    },
  },

  mounted() {
    if(!this.quiz) this.fetchQuiz(this.quizId)
  },

  watch: {
    quiz: {
      handler() {
        if(!this.quiz) return

        this.fetchMyInstanceByQuiz({
          quiz: this.quiz,
          quizzesAttemptSummary: this.quizzesAttemptSummary,
        })
        this.fetchQuizzesAttemptSummary(this.quizzesAttemptSummaryId)
      },
      immediate: true,
    },
  },

}
</script>

