import screenfull from "screenfull"

export default {
  data: () => ({
    fullscreen: false,
  }),
  methods: {
    goFullscreen () {
      if (this.fullscreen) { return }
      this.toggle()
    },
    leaveFullscreen () {
      if (!this.fullscreen) { return }
      this.toggle()
    },
    toggle() {
      if (!screenfull.isEnabled) return

      if (screenfull.isFullscreen) {
        screenfull.exit().then(this.fullscreenChange)
      } else {
        screenfull.request().then(this.fullscreenChange)
      }
    },
    fullscreenChange () {
      this.fullscreen = screenfull.isFullscreen
    },
  },
  mounted() {
    if(!screenfull.isEnabled) return

    screenfull.on("change", this.fullscreenChange)
  },
}
