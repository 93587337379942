<template>
  <div>
    <div
      class="center"
      v-if="showLoader"
    >
      <preloader />
    </div>

    <template v-else>
      <v-alert
        type="info"
        dense
        text
        icon="mdi-information-outline"
        v-if="!quizzesAttemptSummary?.is_answered"
      >
        {{ $t('live.finalScore.examNotTaken') }}
      </v-alert>
      <score-card
        v-else
        :quiz="quiz"
        :score="score"
        :quizzes-attempt-summary="quizzesAttemptSummary"
        :correction-route="{
          name: 'quizCorrection',
          params: { quizId: this.quizId }
        }"
        :retake-exam="retakeExam"
        :calculating-score="displayScoreCalculation"
        :proctoring-data-sent="proctoringDataSent && eventsDataSent"
        :proctoring-data-progress="proctoringDataProgress"
        @upload-audio="uploadAudioIfAny"
      />
    </template>


    <v-card
      v-if="quiz && quiz.state === 'preview'"
      class="mt-5"
      max-width="600"
      style="margin: auto"
      flat
      outlined
    >
      <v-card-title>
        {{ $t('live.finalScore.preview.title') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('live.finalScore.preview.subtitle') }}
      </v-card-subtitle>
      <v-card-text>
        <span v-html="$t('live.finalScore.preview.text')" />
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <eva-button
          primary
          @click="closePreview"
          :label="$t('live.finalScore.preview.close')"
        />
      </v-card-actions>
    </v-card>

    <confetti v-if="withConfetti" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import Preloader from "../../../components/shared/preloader.vue"
import ScoreCard from "../../../components/quiz/scores/score_card.vue"
import Confetti from "../../../components/quiz/scores/confetti.vue"
import { AudioRecorder } from "@/helpers/audioRecorder"
import quizScoreStudentMixin from "../../quizzes/quiz_score_student_mixin"
import { EventStorage } from "@/helpers/studentEventStore"

export default {
  name: "EvalmeeExamScore",
  components: { Confetti, ScoreCard, Preloader },
  mixins: [quizStudentMixin, quizScoreStudentMixin],
  data: () => ({
    withConfetti: false,
    proctoringDataProgress: 0,
    proctoringDataSent: false,
    eventsDataSent: false,
    quizzesAttemptSummaryLoading: false,
    scoreLoading: false,
  }),
  computed: {
    ...mapGetters([
      "currentUser",
      "scoreByQuizAndUserId",
    ]),
    score() {
      return this.scoreByQuizAndUserId(this.quizId, this.currentUser?.id)
    },

    displayScoreCalculation() {
      return this.score &&
        !this.scoresLoading &&
        this.quiz?.show_scores &&
        !this.score.is_up_to_date
    },

    showLoader() {
      if(!this.quiz) return true

      return this.scoreLoading || this.quizzesAttemptSummaryLoading
    },

    audioRecorder() {
      return new AudioRecorder(this.quiz?.id, this.currentUser?.id)
    },
  },
  methods:{
    ...mapActions([
      "fetchScoresByQuiz",
      "retakeQuiz",
      "fetchQuiz",
      "fetchScore",
      "fetchQuizzesAttemptSummary",
    ]),
    retakeExam() {
      this.retakeQuiz(this.quiz)
    },
    closePreview() {
      window.close()
    },

    uploadAudio() {
      this.proctoringDataSent = false
      console.debug("Audio uploading ...")
      this.audioRecorder.uploadTo(
        () => {
          console.debug("Audio uploaded")
          this.proctoringDataSent = true
        },
        (p) => {
          console.debug("progress:", p)
          return (this.proctoringDataProgress = p)
        }
      )
    },

    async uploadAudioIfAny(){
      if(await this.audioRecorder.hasDataToUpload()) return this.uploadAudio()

      console.debug("No audio to upload")
      this.proctoringDataSent = true
    },

    async syncEvents() {
      console.debug("Start syncing events")
      new EventStorage(this.quiz.id).syncAllEvents()
        .then(() => {
          console.debug("Events synced")
          this.eventsDataSent = true
        })
        .catch((e)=>{
          console.debug("Events sync failed", e)
        })
    },

  },

  mounted() {
    if(!this.quiz) this.fetchQuiz(this.quizId)

    this.quizzesAttemptSummaryLoading = true
    this.fetchQuizzesAttemptSummary(this.quizzesAttemptSummaryId)
      .then(() => {this.quizzesAttemptSummaryLoading = false})

    this.scoreLoading = true
    this.fetchScore({ scoreId: this.quizzesAttemptSummaryId })
      .then(() => {this.scoreLoading = false})
  },

  watch: {
    quiz: {
      handler(val) {
        if (val && !this.score?.score) {
          this.uploadAudioIfAny()
          this.syncEvents()
        }
      },
      immediate: true,
    },

    // Fetch the score when `quiz.show_scores` changes to true to get the student grade
    "quiz.show_scores": {
      handler(val, oldVal) {
        if(val === oldVal) return
        if(val) this.fetchScore({ scoreId: this.quizzesAttemptSummaryId })

      },
      immediate: true,
    },

    // DUPLICATE-#12
    // When the student is on the score page, he is redirected to the exam page if the quizzesAttempt is reopened
    "quizzesAttemptSummary.answerability": {
      handler(answerability) {
        if(answerability !== "answering") return

        this.$router.push({ name: "exam" })
      },
      immediate: true,
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === "exam") vm.withConfetti = true
    })
  },
}
</script>

