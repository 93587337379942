<template>
  <div
    class="cheat-modal"
    v-if="currentQuiz && currentQuiz.detect_mouse_leave"
  >
    <v-overlay
      :value="showWarning"
      color="red"
      opacity="0.8"
    />
    <v-dialog
      v-model="showWarning"
      persistent
      attach=".cheat-modal"
      hide-overlay
      max-width="500"
    >
      <v-card>
        <v-card-text class="pt-5">
          <div class="headline center">
            {{ $t("live.stayFocused") }} 😏
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useStore } from "@/store"
import { useMouseLeave } from "@/composables/events/useMouseLeave"
import { useDocumentVisibility, useWindowFocus, watchDebounced } from "@vueuse/core"
import { ref, toRefs, watch } from "vue"

export default {
  name: "CheatModal",
  props: {
    currentQuiz: { type: Object, required: true },
  },
  setup(props) {
    const { hasLeft: mouseHasLeft } = useMouseLeave()
    const visibility = useDocumentVisibility()
    const focused = useWindowFocus()
    const store = useStore()
    const showWarning = ref(false)
    const isOutsideOfExam = ref(false)
    const { currentQuiz } = toRefs(props)
    const createCheatAttempt = () => {
      if (currentQuiz.value.detect_mouse_leave) {
        store.dispatch("addCheatAttempt", currentQuiz.value)
      }
    }

    watch(
      [mouseHasLeft, visibility, focused],
      ([mouseHasLeft, visibility, focused]) => {
        isOutsideOfExam.value = mouseHasLeft || visibility === "hidden" || !focused
        showWarning.value = isOutsideOfExam.value
      }
    )

    watchDebounced(
      isOutsideOfExam,
      (newValue) => {if (newValue) createCheatAttempt()},
      { debounce: 1000 },
    )

    return { showWarning }
  },
}
</script>
